import type { FC } from 'react';
import type { IThumbnailSlide } from '../../swiper/gallery.types';
import styles from './thumbnail.module.scss';
import { ImageWrapper } from 'components/image-wrapper';

export const Thumbnail: FC<IThumbnailSlide> = ({ thumbnail, compressed, isActive }) => {
  return (
    <div
      className={`${styles.thumbnail} ${
        isActive ? styles.thumbnail_active : styles.thumbnail_inactive
      }`}
    >
      <ImageWrapper src={compressed || thumbnail} alt={'thumbnail'} />
    </div>
  );
};
