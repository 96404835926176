import type { FC } from 'react';
import type { ControlsProps } from '../../player-hud.types';

import { Button } from '../../../../common';
import { Icon, Icons } from '../../icon/icon';
import { VolumeChanger } from '../../volume-changer/volume-changer';

import styles from '../controls.module.scss';


export const ControlsWithVolumeChanger: FC<ControlsProps> = ({
  showVolumeChanger,
  mute,
  volume,
  playing,
  onPlay,
  onMute,
  onVolumeChange,
  onPrevious,
  onNext,
}) => {
  return (
    <div className={styles['player-hud-container-controls']}>
      {showVolumeChanger && (
        <VolumeChanger
          volume={volume}
          mute={mute}
          editable={false}
          onChange={(val) => onVolumeChange(val)}
          onMute={onMute}
        />
      )}
      <div className={styles['player-hud-container-controls-center']}>
        <Button
          leftIcon={
            <Icon icon={Icons.BACKWARD} className={styles['player-hud-icon']} />
          }
          typeBtn={'uncolored'}
          onClick={onPrevious}
        />
        {!playing ? (
          <Button
            leftIcon={
              <Icon icon={Icons.PLAY} className={styles['player-hud-icon']} />
            }
            typeBtn={'uncolored'}
            onClick={onPlay}
          />
        ) : (
          <Button
            leftIcon={
              <Icon icon={Icons.PAUSE} className={styles['player-hud-icon']} />
            }
            typeBtn={'uncolored'}
            onClick={onPlay}
          />
        )}
        <Button
          leftIcon={
            <Icon icon={Icons.FORWARD} className={styles['player-hud-icon']} />
          }
          typeBtn={'uncolored'}
          onClick={onNext}
        />
      </div>
      <div className={styles['player-hud-container-controls-left']}>
      </div>
    </div>
  );
};
