import { FC, memo } from 'react';
import type { MainImageProps } from '../main-image.types';

import styles from '../main-image.module.scss';
import { MapLoader } from '../../../../common';
import { ImageWrapper } from 'components/image-wrapper';

export const MainImage: FC<MainImageProps> = memo(({ original, fullScreenHandler }) => {
  return (
    <div
      onClick={fullScreenHandler}
      className={styles['image-container']}
      style={{ width: '80%' }}
    >
      <ImageWrapper
        src={original}
        style={{ height: 'auto' }}
        wrapperStyles={{
          maxHeight: '100%',
          borderRadius: 16,
          overflow: 'hidden',
          width: 'auto',
        }}
        thumb={<MapLoader className={styles['image-container-MapLoader']} />}
      />
      <div className={styles['image-content-actions']} />
    </div>
  );
});
