import type { FC } from 'react';
import type { MainImageProps } from '../main-image.types';
import styles from '../main-image.module.scss';
import { ImageWrapper } from 'components/image-wrapper';

export const MainImageFullscreen: FC<MainImageProps> = ({ original, originalAlt }) => {
  return (
    <div className={styles.image_fullscreen}>
      <ImageWrapper
        className={styles['image_fullscreen-image']}
        src={original}
        alt={originalAlt}
        wrapperStyles={{ width: 'auto' }}
      />
    </div>
  );
};
