import type { FC } from 'react';
import type { PlayerHUDProps } from '../../player-hud.types';

import { PlayerTimeline } from '../../player-timeline/player-timeline';
import { VideoDuration } from '../../video-duration/video-duration';
import { Controls } from '../../controls';

import { format } from '../../video-duration/utils';

import styles from './player-hud.mobile.module.scss';
import classnames from 'classnames';


export const PlayerHUDMobile: FC<PlayerHUDProps> = ({
  showVolumeChanger,
  mute,
  loaded,
  played,
  volume,
  playing,
  duration,
  fullscreen,
  onPlay,
  onMute,
  onPlayedChange,
  onSeekStart,
  onSeekEnd,
  onVolumeChange,
  onPrevious,
  onNext,
}) => {
  return (
    <div
      className={classnames(
        styles['player-hud-container'],
        fullscreen && styles['player-hud-container_fullscreen'],
      )}
      data-type="hud"
    >
      <Controls
        type={showVolumeChanger ? 'withvolume' : 'withoutvolume'}
        showVolumeChanger={showVolumeChanger}
        mute={mute}
        volume={volume}
        playing={playing}
        onPlay={onPlay}
        onPrevious={onPrevious}
        onNext={onNext}
        onMute={onMute}
        onVolumeChange={onVolumeChange}
      />
      <VideoDuration
        seconds={duration}
        currentTime={played * duration}
        className={styles['player-hud-container-time']}
      />
      <PlayerTimeline
        className={styles['player-hud-container-duration']}
        isMobile
        value={played}
        loaded={loaded}
        tooltipText={format(played * duration)}
        hoveredTooltipText={(value) => format(value * duration)}
        onChange={onPlayedChange}
        onSeekStart={onSeekStart}
        onSeekEnd={onSeekEnd}
      />
    </div>
  );
};
