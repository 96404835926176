import React, { EventHandler, useState } from 'react';
import { IObjectInfoProps } from './object-info.types';
import styles from './object-info.module.scss';
import { SpanTag, ToolTip } from '../common';
import { getContentValues, getRatingColor } from './utils';
import { Heading, directions, nonprofit } from '../../constants';
import { ObjectDelete } from '../object-delete/object-delete';
import { setFillColor } from '../view/map-view/layer-feature/utils';
import {
  clearProject,
  filterObjects,
  setGalleryObjectId,
  setMonitoringOpen,
  useAppDispatch,
} from '../../state';
import { batch } from 'react-redux';
import { useSelectObject } from '../../hooks/depended/useSelectObject';
import { SidebarObjectInfoHeading } from '../sidebar/sidebar-constants';

export const ObjectInfo = ({
  object,
  dividerTextLength = 40,
  onCloseSidebar,
  className = '',
  ...props
}: IObjectInfoProps): JSX.Element => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [, setSelectedObjectId] = useSelectObject();

  const contentValues = getContentValues(object!);

  const submitFiltersHandler = (
    selectedNationalProjects: number[],
    selectedDirections: number[],
    e: React.MouseEvent,
  ) => {
    e.stopPropagation();
    setSelectedObjectId(null);
    batch(() => {
      dispatch(setGalleryObjectId(null));
      dispatch(clearProject());
      dispatch(setMonitoringOpen(false));
      dispatch(
        filterObjects({
          macro_region: [],
          program: [],
          start_of_building: [],
          type_of_work: [],
          status: [],
          showOnlyFavorite: false,
          national_projects: selectedNationalProjects,
          directions: selectedDirections,
          degree_of_satisfaction: { min: 0, max: 10 },
          gazprom: [],
        }),
      );
    });
  };

  return (
    <div
      className={`${styles['object-info']} ${className}`}
      // style={type === 'card' ? { marginTop: 0, cursor: 'pointer' } : {}}
      {...props}
    >
      {deleteModal && (
        <ObjectDelete
          title={object?.name}
          objectId={object?.id}
          onClose={(e) => setDeleteModal(e)}
          onCloseSidebar={onCloseSidebar}
        />
      )}
      <div className={styles['object-info-content']}>
        {contentValues.rating ? (
          <ToolTip
            isVisible={
              Number(contentValues.rating.length) + Number(Heading.rating.length) >
              dividerTextLength
            }
            content={contentValues.rating}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.rating}
              <span
                className={styles['object-info-content-value-rating']}
                style={{
                  backgroundColor: getRatingColor(Number(object!.rating)),
                }}
              >
                {contentValues.rating}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {contentValues.passability ? (
          <ToolTip
            isVisible={
              Number(contentValues?.passability?.length) +
                Number(Heading?.passability?.length) >
              dividerTextLength
            }
            content={contentValues.passability}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.passability}
              <span className={styles['object-info-content-value']}>
                {contentValues.passability}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {contentValues.employeeVisits ? (
          <ToolTip
            isVisible={
              Number(contentValues?.employeeVisits?.length) +
                Number(Heading?.employeeVisits?.length) >
              dividerTextLength
            }
            content={contentValues.employeeVisits}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.employeeVisits}
              <span id={'valueId'} className={styles['object-info-content-value']}>
                {contentValues.employeeVisits}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {contentValues.necessaryInvest ? (
          <ToolTip
            isVisible={
              Number(contentValues?.necessaryInvest?.length) +
                Number(Heading?.necessaryInvest?.length) >
              dividerTextLength
            }
            content={contentValues.necessaryInvest}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.necessaryInvest}
              <span className={styles['object-info-content-value']}>
                {contentValues.necessaryInvest}
              </span>
            </div>
          </ToolTip>
        ) : null}

        {contentValues.nationalProject ? (
          <ToolTip
            isVisible={
              Number(contentValues?.nationalProject?.length) +
                Number(Heading?.nationalProject?.length) >
              dividerTextLength
            }
            content={contentValues.nationalProject}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.nationalProject}
              <span
                className={styles['object-info-content-value__a']}
                onClick={(e: React.MouseEvent) =>
                  submitFiltersHandler([Number(object!.national_project_id)], [], e)
                }
              >
                {contentValues.nationalProject}
              </span>
            </div>
          </ToolTip>
        ) : null}

        {contentValues.direction ? (
          <ToolTip
            isVisible={
              Number(contentValues.direction.length) +
                Number(Heading?.direction?.length) >
              43
            }
            content={contentValues.direction}
          >
            <div className={styles['object-info-content-title']}>
              {SidebarObjectInfoHeading.direction}
              <span
                className={styles['object-info-content-value__a']}
                onClick={(e: React.MouseEvent) =>
                  submitFiltersHandler([], [Number(object?.direction_id)], e)
                }
              >
                {contentValues.direction.length > 30
                  ? `${contentValues.direction.slice(0, 30)}...`
                  : contentValues.direction}
              </span>
            </div>
          </ToolTip>
        ) : null}

        {/* {contentValues.name ? (
          <ToolTip
            isVisible={
              Number(contentValues.name.length) + Number(Heading.name.length) >
              dividerTextLength
            }
            content={contentValues.name}>
            <div className={styles['object-info-content-title']}>
              {Heading.name}
              <span className={styles['object-info-content-value']}>
                {contentValues.name}
              </span>
            </div>
          </ToolTip>
        ) : null} */}

        {contentValues.place ? (
          <ToolTip
            isVisible={
              Number(contentValues.place.length) + Number(Heading.place.length) > 37
            }
            content={contentValues.place}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.place}
              <span className={styles['object-info-content-value']}>
                {contentValues.place}
              </span>
            </div>
          </ToolTip>
        ) : null}

        {contentValues.distanceDistrictCenter ? (
          <ToolTip
            isVisible={
              Number(contentValues?.distanceDistrictCenter?.length) +
                Number(Heading?.distanceDistrictCenter?.length) >
              dividerTextLength
            }
            content={contentValues.distanceDistrictCenter}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.distanceDistrictCenter}
              <span className={styles['object-info-content-value']}>
                {contentValues.distanceDistrictCenter}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {contentValues.owner ? (
          <ToolTip
            isVisible={
              Number(contentValues.owner.length) + Number(Heading?.owner?.length) >
              dividerTextLength
            }
            content={contentValues.owner}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.owner}
              <span className={styles['object-info-content-value']}>
                {contentValues.owner}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {contentValues.macroRegionValue ? (
          <ToolTip
            isVisible={
              Number(contentValues?.macroRegionValue?.length) +
                Number(Heading?.macroRegionValue?.length) >
              dividerTextLength
            }
            content={contentValues.macroRegionValue}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.macroRegionValue}
              <span className={styles['object-info-content-value']}>
                {contentValues.macroRegionValue}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {contentValues.programValue ? (
          <ToolTip
            isVisible={
              Number(contentValues.programValue.length) +
                Number(Heading?.programValue?.length) >
              dividerTextLength
            }
            content={contentValues.programValue}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.programValue}
              <span className={styles['object-info-content-value']}>
                {contentValues.programValue}
              </span>
            </div>
          </ToolTip>
        ) : null}
        {/*<ToolTip*/}
        {/*  isVisible={*/}
        {/*    Number(contentValues.typeOfWork!.length) +*/}
        {/*        Number(Heading?.typeOfWork?.length) >*/}
        {/*      dividerTextLength*/}
        {/*  }*/}
        {/*  content={contentValues.typeOfWork}*/}
        {/*>*/}
        {/*  <div className={styles['object-info-content-title']}>*/}
        {/*    {Heading.typeOfWork}*/}
        {/*    <span className={styles['object-info-content-value']}>*/}
        {/*      {contentValues.typeOfWork}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</ToolTip>*/}

        {contentValues.realizationPeriod ? (
          <ToolTip
            isVisible={
              Number(contentValues.realizationPeriod.length) +
                Number(Heading.realizationPeriod.length) >
              dividerTextLength
            }
            content={contentValues.realizationPeriod}
          >
            <div className={styles['object-info-content-title']}>
              {Heading.realizationPeriod}
              <span
                dangerouslySetInnerHTML={{
                  __html: contentValues.realizationPeriod.trim(),
                }}
                className={styles['object-info-content-value']}
              />
            </div>
          </ToolTip>
        ) : null}
      </div>
    </div>
  );
};
