const pad = (val: string) => {
  return ('0' + val).slice(-2);
};

export const format = (seconds: number) => {
  const date = new Date(seconds * 1000);

  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds().toString());

  return hh ? `${hh}:${pad(mm.toString())}:${ss}` : `${mm}:${ss}`;
};
