import styles from './sidebar.module.scss';
import type { SidebarImageProps } from './sidebar.types';
import thumb from 'images/object-bg-image.png';
import { Image } from 'components/common';
import { useEffect, useState } from 'react';

export const SidebarImage = ({
  getImage,
  alt,
  ...props
}: SidebarImageProps): JSX.Element => {
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    const getImageHandler = async () => {
      const url = await getImage();
      setImage(String(url));
    };
    getImageHandler();
  }, [getImage, alt]);

  return (
    <div className={styles.image} {...props}>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <Image
          src={image}
          loaded={!!image}
          error={null}
          style={{
            minWidth: '100%',
            minHeight: '100%',
          }}
          thumb={thumb}
          alt={alt}
        />
      </span>
    </div>
  );
};
