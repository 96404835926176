import { FC } from 'react';
import { SpanTag } from '../../../common';
import type { VideoDurationProps } from './video-duration.type';
import { format } from './utils';

export const VideoDuration: FC<VideoDurationProps> = ({
  seconds,
  currentTime,
  ...props
}) => {
  return (
    <SpanTag
      // type={'caption'}
      x={'2.75'}
      weight={2}
      {...props}
    >
      {format(currentTime)} / {format(seconds)}
    </SpanTag>
  );
};
