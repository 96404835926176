import styles from './map-loader.module.scss';
import Spinner from '../spinner/spinner';

export const MapLoader = ({ className = '' }: { className?: string }) => {
  return (
    <div className={`${styles.loader} ${className}`}>
      <Spinner />
    </div>
  );
};
