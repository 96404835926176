import { useState, useRef, memo } from 'react';
import { Image } from 'components';
import { useIntersectionObserver } from 'hooks';
import type { ImageWrapperProps } from './image-wrapper.types';
import { fetchImage } from 'utils';
import styles from './image-wrapper.styles.module.scss';

export const ImageWrapper = ({
  src,
  alt = '',
  thumb,
  onLoad = () => {},
  wrapperStyles = {},
  wrapperClassName = '',
  ...props
}: ImageWrapperProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getImageHandler = async () => {
    try {
      const resImage = await fetchImage(src);

      setImage(resImage);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else if (typeof error === 'string') {
        setError(error);
      }
    } finally {
      setLoaded(true);
    }
  };

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          if (!!src) {
            setIsVisible(true);

            getImageHandler();
          }
        }
        ref.current && observerElement.unobserve(ref.current);
      }
    },
  });

  return (
    <div
      ref={ref}
      className={`${styles.container} ${wrapperClassName}`}
      style={{ width: '100%', ...wrapperStyles }}
    >
      <Image
        className={styles.image}
        onLoad={onLoad}
        src={image}
        thumb={thumb}
        alt={alt}
        loaded={loaded}
        error={error}
        {...props}
      />
    </div>
  );
};
