import { Swiper } from 'swiper';
import type { IGallerySlide } from '../swiper/gallery.types';
import type { VideoGalleryProps, VideoGalleryRef } from './video-gallery.types';
import type { VideoPlayerRef } from '../../video-player/video-player.types'; 
import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';
import screenfull from 'screenfull';
import { Gallery } from '../swiper'; 
import { VideoGalleryContextProvider, VideoGalleryContext } from './context';
import { Slide } from './slide';
import { Thumbnail } from './thumbnail';
import styles from './video-gallery.module.scss';
import classnames from 'classnames';
import { useBreakpointsComparison } from '../../../hooks';
import { ScreenSize } from '../../../constants';


const VideoGallery = React.forwardRef<VideoGalleryRef, VideoGalleryProps>(
  (
    {
      items,
      shareUrl,
      showVolumeChanger,
      onSlideChange = () => {},
      index = 0,
      mobileSize = 500,
      startFrom,
      defaltFullscreen = false,
      customFullScreen = false,
      customFullScreenFunction = () => {},
    },
    ref,
  ) => {
    const { setIsFullscreen, setCurrentItem } = useContext(VideoGalleryContext);
    const videoPlayerRefs = useRef<Map<number | string, VideoPlayerRef>>(
      new Map(),
    );

    const isMobile = useBreakpointsComparison(ScreenSize.MP);
    const isTablet = useBreakpointsComparison(ScreenSize.TP);
    const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
    const [initIndex] = useState(index);
    const [mainSwiper, setMainSwiper] = useState<Swiper>();

    const openFullscreen = useCallback((swiper: Swiper) => {
      // Раскрываем враппер галлереи на полный экран
      screenfull.request(swiper.el);
      /**
       * При закрытии устанавлием стейт в false (чтобы корректно отробатывал esc)
       * и меняем стили галлереи на стандартные
       */
      screenfull.onchange((_) => {
        if (!screenfull.isFullscreen) {
          setIsFullscreen(false);
          customFullScreenFunction(false);
        }
      });
      setIsFullscreen(true);
    }, []);

    const closeFullscreen = useCallback(() => {
      // Выходим из поного экрана
      screenfull.exit();
      setIsFullscreen(false);
    }, []);

    const onFullScreen = useCallback(
      (value: boolean) => {
        if (!mainSwiper) return;

        if (customFullScreen) {
          customFullScreenFunction(value, mainSwiper);
          return;
        }

        if (value) {
          openFullscreen(mainSwiper);
        } else {
          closeFullscreen();
        }
      },
      [mainSwiper],
    );

    const mainSlideRender = useCallback(
      (props: IGallerySlide) =>
        // пока галерея не инициализирована выводим лоадер
        mainSwiper ? (
          <Slide
            ref={(ref) => ref && videoPlayerRefs.current.set(props.id, ref)}
            mobileSize={mobileSize}
            showVolumeChanger={showVolumeChanger}
            shareUrl={shareUrl}
            onFullScreen={onFullScreen}
            startFrom={startFrom}
            {...props}
          />
        ) : (
          <div
            className={classnames(
              'swiper-lazy-preloader',
              styles['image-loader'],
            )}
          />
        ),
      [onFullScreen, shareUrl],
    );

    const changeCurrentItem = useCallback((swiper: Swiper) => {
      onSlideChange(items[swiper.realIndex]);
      setCurrentItem(items[swiper.realIndex]);
    }, []);

    const toSlide = useCallback(
      (idx: number) => {
        if (mainSwiper) {
          mainSwiper.slideToLoop(idx);
        }
      },
      [mainSwiper],
    );

    useEffect(() => {
      if (mainSwiper) {
        if (defaltFullscreen) openFullscreen(mainSwiper);
        // Первый слайд
        changeCurrentItem(mainSwiper);
        // Обновление слайдов
        mainSwiper.on('slideChange', changeCurrentItem);
      }
    }, [mainSwiper]);

    useEffect(() => {
      return () => {
        mainSwiper?.off('slideChange', changeCurrentItem);
      };
    }, []);

    useEffect(() => {
      toSlide(index);
    }, [index]);

    useImperativeHandle(ref, () => ({
      openFullScreen: () => onFullScreen(true),
    }));

    return (
      <div className={styles.gallery}>
        <Gallery
          items={items}
          initialSlide={initIndex}
          loop={true}
          lazy={false}
          slide={mainSlideRender}
          withThumbnail={!isTabletLandscape}
          withNavButtons={false}
          onMainSwiperInit={setMainSwiper}
          mainWrapperClassName={styles['gallery-slides']}
          thumbnailGap={!isMobile ? 20 : 8}
          thumbnailVisableCount={!isMobile ? (!isTablet ? 6 : 4) : 2}
          thumbnail={Thumbnail}
          thumbnailWrapperClassName={styles['gallery-thumbnails']}
          withDrag={false}
          withEffect={false}
        />
      </div>
    );
  },
);

// Враппер для добавления контекста
const VideoGalleryWrapper = React.forwardRef<
VideoGalleryRef,
VideoGalleryProps
>(({ ...props }, ref) => {
  return (
    <VideoGalleryContextProvider>
      <VideoGallery ref={ref} {...props} />
    </VideoGalleryContextProvider>
  );
});

export { VideoGalleryWrapper as VideoGallery };
