import type { FC } from 'react';
import type { PlayerHUDPropsWithType } from './player-hud.types';

import React from 'react';

import * as HUDTypes from './player-hud-types';


export const PlayerHUD: FC<PlayerHUDPropsWithType> = ({
  type = 'desktop',
  ...props
}) => {
  return React.createElement(HUDTypes[type], props);
};
