import { FC } from 'react';
import type { InputProps } from './player-timeline.types';

import React, { useEffect, useState, useRef, useCallback } from 'react';

import styles from './player-timeline.module.scss';
import classnames from 'classnames';


export const PlayerTimeline: FC<InputProps> = ({
  value = 0,
  loaded = 0,
  tooltipText = '',
  isMobile = false,
  className,
  onChange = () => {},
  onSeekStart = () => {},
  onSeekEnd = () => {},
  hoveredTooltipText: hoveredTooltipTextProp,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredValue, setHoveredValue] = useState<number>(0);
  const [clicked, setClicked] = useState<boolean>(false);
  const [hoveredTooltipText, setHoveredTooltipText] = useState<
  string | undefined
  >();

  const calculateHoveredTooltipText = useCallback(
    (value = 0) =>
      setHoveredTooltipText(
        typeof hoveredTooltipTextProp === 'function'
          ? hoveredTooltipTextProp(value)
          : hoveredTooltipTextProp,
      ),
    [hoveredTooltipTextProp],
  );

  const onEnter = useCallback(() => {
    setHovered(true);
    setClicked(false);
    setHoveredValue(0);
    if (inputRef.current) {
      inputRef.current.style.cursor = 'pointer';
    }
  }, []);

  const onLeave = useCallback(() => {
    setHovered(false);
    setClicked(false);
    setHoveredValue(0);
    if (inputRef.current) {
      inputRef.current.style.cursor = 'auto';
    }
    clicked && onSeekEnd();
  }, [clicked]);

  const onDown = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.cursor = 'grabbing';
    }
    setClicked(true);
    onSeekStart();
  }, []);

  const onUp = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.style.cursor = hovered ? 'pointer' : 'auto';
    }
    setClicked(false);
    onSeekEnd();
  }, [hovered]);

  const getHoverMouse = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (inputRef.current && hovered) {
        const containerRect = inputRef.current.getBoundingClientRect();

        const padding = event.nativeEvent.x - containerRect.x;

        if (padding < containerRect.width && padding > 0) {
          const value = padding / containerRect.width;
          setHoveredValue(value);
          calculateHoveredTooltipText(value);
        } else {
          onLeave();
          calculateHoveredTooltipText();
        }
      }
    },
    [hovered, onLeave, calculateHoveredTooltipText],
  );

  const getHoverTouch = useCallback<React.TouchEventHandler<HTMLDivElement>>(
    (event) => {
      if (event.touches.length !== 1) {
        onLeave();
        onUp();
        return false;
      }

      if (inputRef.current) {
        const containerRect = inputRef.current.getBoundingClientRect();
        const touchInfo = event.touches[0];

        const padding = touchInfo.clientX - containerRect.x;

        if (padding < containerRect.width && padding > 0) {
          const value = padding / containerRect.width;
          setHoveredValue(value);
          calculateHoveredTooltipText(value);
        } else {
          onLeave();
          onUp();
          calculateHoveredTooltipText();
        }
      }
    },
    [onLeave, onUp, calculateHoveredTooltipText],
  );

  const touchStart = useCallback<React.TouchEventHandler<HTMLDivElement>>(
    (event) => {
      onDown();
      getHoverTouch(event);
    },
    [onDown, getHoverTouch],
  );

  useEffect(() => {
    calculateHoveredTooltipText();
  }, []);

  useEffect(() => {
    if (clicked) {
      onChange(hoveredValue);
    }
  }, [clicked, hoveredValue, onChange]);

  return (
    <div
      ref={inputRef}
      className={classnames(styles['input-container'], className)}
      onMouseMove={getHoverMouse}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onMouseDown={onDown}
      onMouseUp={onUp}
      onTouchStart={touchStart}
      onTouchMove={getHoverTouch}
      onTouchEnd={onUp}
    >
      <div className={classnames(styles.input_background)} />
      <div
        className={classnames(styles.input_loaded)}
        style={{
          width: `${loaded * 100}%`,
        }}
      />
      {!isMobile && (
        <div
          className={classnames(styles.input_hovered)}
          style={{
            width: clicked ? 0 : `${hoveredValue * 100}%`,
          }}
        />
      )}
      <div
        className={classnames(
          styles.input_progress,
          clicked && styles['input_progress_without-effects'],
        )}
        style={{
          width: `${value * 100}%`,
        }}
      />
      <div
        className={classnames(
          styles['input-dot'],
          !clicked && styles['input-dot_hidden'],
        )}
        style={{
          left: `${hoveredValue * 100}%`,
        }}
      />
      <div
        className={classnames(
          styles['input-tooltip'],
          isMobile && styles['input-tooltip_mobile'],
          isMobile && clicked && styles['input-tooltip_visable'],
        )}
        style={{
          left: `${hoveredValue * 100}%`,
        }}
      >
        <span>{hovered ? hoveredTooltipText : tooltipText}</span>
      </div>
    </div>
  );
};
