import type { FC } from 'react';
import type { IThumbnailSlide } from '../../swiper/gallery.types'; 
import styles from './thumbnail.module.scss';
import classname from 'classnames';


export const Thumbnail: FC<IThumbnailSlide> = ({ thumbnail, isActive }) => {
  return (
    <div
      className={classname(
        styles.thumbnail,
        isActive && styles.thumbnail_active,
      )}
    >
      <img
        src={thumbnail}
        alt={'thumbnail'}
        className={styles['thumbnail-image']}
      />
    </div>
  );
};
