import type { HTMLAttributes } from 'react';


export enum Icons {
  BACKWARD = 'icon icon-backward icon-middle',
  DOWNlOAD = 'icon icon-download',
  FORWARD = 'icon-forward',
  OPEN = 'icon-open-2',
  PAUSE = 'icon icon-pause icon-middle',
  PLAY = 'icon icon-play icon-middle',
  VOLUME1 = 'icon-volume-down',
  VOLUME2 = 'icon-volume-up',
  VOLUME_MUTE = 'icon-volume-off',
  CLOSE = 'icon-close-2',
}

export interface IconProps extends HTMLAttributes<HTMLElement> {
  icon: Icons
}
