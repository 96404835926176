import { FC, useState, useCallback, useEffect, useRef } from 'react';
import type { Swiper } from 'swiper';
import type { IGallerySlide } from '../swiper/gallery.types';
import type { ImageGalleryProps } from './image-galery.types';
import screenfull from 'screenfull';
import { Gallery } from '../swiper';
import { useDebouncedEffect } from '../../../hooks';
import { useBreakpointsComparison } from '../../../hooks';
import { MainImage } from './main-image';
import { Thumbnail } from './thumbnail';
import styles from './image-gallery.module.scss';
import slideStyles from './main-image/main-image.module.scss';
import classnames from 'classnames';
import { ScreenSize } from '../../../constants';

export const ImageGallery: FC<ImageGalleryProps> = ({
  items,
  onSlideChange = () => {},
  initIndex = 0,
  thumbnailClassName = '',
  galleryClassName = '',
  navigateButtonPrevClassName = '',
  navigateButtonNextClassName = '',
}) => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const isMobile = useBreakpointsComparison(ScreenSize.MP);

  const [mainSwiper, setMainSwiper] = useState<Swiper>();
  const [isFullscreen, setIsFullScreen] = useState<boolean>(false);
  const [displayNavButtons, setDisplayNavButtons] = useState<boolean>(true);
  const navButtonTimeout = useRef<NodeJS.Timeout>();

  const openFullscreen = useCallback((swiper: Swiper) => {
    // Раскрываем враппер галлереи на полный экран
    screenfull.request(swiper.el);
    /**
     * При закрытии устанавлием стейт в false (чтобы корректно отробатывал esc)
     * и меняем стили галлереи на стандартные
     */
    screenfull.onchange((_) => {
      if (!screenfull.isFullscreen) {
        clearTimeout(navButtonTimeout.current);
        setIsFullScreen(false);
        setDisplayNavButtons(true);
      }
    });
    clearTimeout(navButtonTimeout.current);
    setIsFullScreen(true);
    setDisplayNavButtons(true);
  }, []);

  const closeFullscreen = useCallback(() => {
    // Выходим из поного экрана
    screenfull.exit();
    setIsFullScreen(false);
  }, []);

  const onFullScreen = useCallback(
    (value: boolean) => {
      if (!mainSwiper) return;

      if (value) {
        openFullscreen(mainSwiper);
      } else {
        closeFullscreen();
      }
    },
    [mainSwiper],
  );

  const fullScreenHandler = () => {
    if (!isTabletLandscape) {
      onFullScreen(true);
    }
  };

  const mainSlideRender = useCallback(
    (props: IGallerySlide) => {
      return (
        <MainImage
          type={isFullscreen ? 'fullscreen' : 'standart'}
          fullScreenHandler={fullScreenHandler}
          isMobile={isTablet}
          {...props}
        />
      );
    },
    [isFullscreen, onFullScreen, items],
  );

  useEffect(() => {
    if (mainSwiper) {
      // console.log('mainSwiper: ', mainSwiper);
      // console.log('items: ', items);
      // Первый слайд
      // if (items[mainSwiper.realIndex]) {
      //   console.log('mainSwiper: ', items[mainSwiper.realIndex]);
      //   onSlideChange(items[mainSwiper.realIndex].id);
      // } else if (!items[mainSwiper.realIndex] && items[mainSwiper.realIndex + 1]) {
      //   onSlideChange(items[mainSwiper.realIndex + 1].id);
      // } else if (!items[mainSwiper.realIndex] && items[mainSwiper.realIndex - 1]) {
      //   onSlideChange(items[mainSwiper.realIndex - 1].id);
      // }
      // Обновление слайдов
      // console.log('11111111111111: ', mainSwiper.realIndex);
      // mainSwiper.on('slideChange', (swiper) => {
      // onSlideChange(items[mainSwiper.realIndex].id);
      // });
    }
  }, [mainSwiper, items]);

  useEffect(() => {
    console.log('initIndex: ', initIndex);
    onSlideChange(items[initIndex].id);
  }, [initIndex, mainSwiper]);

  const checkMouseMove = useCallback(() => {
    clearTimeout(navButtonTimeout.current);
    setDisplayNavButtons(true);
    navButtonTimeout.current = setTimeout(() => {
      setDisplayNavButtons(false);
    }, 5000);
  }, [mainSwiper]);

  useDebouncedEffect(() => {
    if (isFullscreen) {
      document.addEventListener('mousemove', checkMouseMove);

      return () => {
        document.removeEventListener('mousemove', checkMouseMove);
      };
    }
  }, [isFullscreen]);

  const getVisibleThumbnailsCountValue = () => {
    if (!isTabletLandscape) return 6;
    if (!isTablet) return 5;
    if (!isMobile) return 3;
    return 2;
  };

  return (
    <div className={styles.gallery}>
      <Gallery
        items={items}
        initialSlide={initIndex}
        withThumbnail={true}
        withNavButtons={!isTablet}
        visableNavButtons={displayNavButtons}
        onMainSwiperInit={setMainSwiper}
        slide={mainSlideRender}
        mainWrapperClassName={classnames(
          styles['gallery-slides'],
          !displayNavButtons && styles['gallery_hide-cursor'],
          galleryClassName,
        )}
        thumbnailGap={!isMobile ? 20 : 8}
        thumbnailVisableCount={getVisibleThumbnailsCountValue()}
        thumbnail={Thumbnail}
        thumbnailWrapperClassName={classnames(
          styles['gallery-thumbnails'],
          thumbnailClassName,
        )}
        navigateButtonPrevClassName={navigateButtonPrevClassName}
        navigateButtonNextClassName={navigateButtonNextClassName}
        withDrag={!isFullscreen}
        withEffect={!isFullscreen}
        withCloseButton={isFullscreen && displayNavButtons}
        onCloseButton={closeFullscreen}
        slideLoadingClassName={slideStyles['image-content_loading']}
        slideLoadedClassName={slideStyles['image-content_loaded']}
      />
    </div>
  );
};
