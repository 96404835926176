import type { FC } from 'react';
import type { IconProps } from './icon.types';
import { Icons } from './icon.types';

import React from 'react';

import classnames from 'classnames';


const Icon: FC<IconProps> = React.memo(({ icon, className, ...props }) => {
  return <i className={classnames(icon, className)} {...props} />;
});

export { Icon, Icons };
