import React, { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ObjectButtonsProps } from './object-buttons.types';
import { batch } from 'react-redux';
import styles from './object-buttons.module.scss';
import { ButtonGroup } from '../common/button-group';
import { Button, SpanTag, ToolTip } from '../common';
import {
  setAnalyticsViewOpen,
  setGalleryObjectId,
  setMonitoringOpen,
  toggleCoordsEditor,
  useAppDispatch,
  useDeleteObjectSelector,
  useDeviceSelector,
  useGalleryObjectIdSelector,
  useIsAnalyticsViewOpenSelector,
  useIsMonitoringOpenSelector,
  useToggleFavoriteUserItemMutation,
} from '../../state';
import { useFavoriteObjectsSelector } from '../../state/selector';
import { useFlyToObjectOnMap } from '../../hooks';
// import { getContentValues } from './utils';
import { Heading } from '../../constants';
import { ObjectDelete } from '../object-delete/object-delete';
import { shareObject } from '../../utils';

export const ObjectButtons = ({
  editorMode,
  object,
  type = 'sidebar',
}: ObjectButtonsProps): JSX.Element => {
  const [toggleFavoriteUserItem, { isLoading: toggleFavoriteButtonLoading }] =
    useToggleFavoriteUserItemMutation();
  const favoriteItems = useFavoriteObjectsSelector();
  const isAnalyticsViewOpen = useIsAnalyticsViewOpenSelector();
  const dispatch = useAppDispatch();
  const flyTo = useFlyToObjectOnMap();
  const isMonitoringOpen = useIsMonitoringOpenSelector();
  const [isShareToolTipActive, setShareToolTipActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const galleryImgId = useGalleryObjectIdSelector();
  const isDeleteObject = useDeleteObjectSelector();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { isMobile } = useDeviceSelector();
  // const contentValues = getContentValues(object);

  const shareButtonHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (window && object) {
      shareObject(object.id);
      setShareToolTipActive(true);
      setTimeout(() => setShareToolTipActive(false), 3000);
    }
  };

  const toggleFavorite = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (object) {
      toggleFavoriteUserItem({ id: object.id });
    }
  };

  const flyToObject = () => {
    if (object) {
      flyTo([object.coords.coordinates[0], object.coords.coordinates[1]]);
    }
  };

  // useEffect(() => {
  //   if (toggleFavoriteButtonLoading) {

  //   }
  // }, [toggleFavoriteButtonLoading]);

  const handleOpenMonitoring = () => {
    if (galleryImgId !== null && !isMonitoringOpen) {
      batch(() => {
        dispatch(setGalleryObjectId(null));
        dispatch(setAnalyticsViewOpen(false));
        dispatch(setMonitoringOpen(!isMonitoringOpen));
      });
    }
    if (galleryImgId !== null && isMonitoringOpen) {
      dispatch(setMonitoringOpen(!isMonitoringOpen));
    }
    batch(() => {
      dispatch(setMonitoringOpen(!isMonitoringOpen));
      dispatch(setAnalyticsViewOpen(false));
    });
  };

  const handleOpenAnalytics = () => {
    if (galleryImgId !== null && !isAnalyticsViewOpen) {
      batch(() => {
        dispatch(setGalleryObjectId(null));
        dispatch(setMonitoringOpen(false));
        dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
      });
    }
    if (galleryImgId !== null && isAnalyticsViewOpen) {
      dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
    }
    batch(() => {
      dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
      dispatch(setMonitoringOpen(false));
    });
  };

  return (
    <>
      {((!editorMode && !isDeleteObject) || (!editorMode && type === 'sidebar')) && (
        <>
          <Button
            className={styles['object-info-buttons-group-button-display']}
            elementType={'button'}
            typeBtn={'secondary'}
            children={!isMobile && 'Показать на карте'}
            size={type === 'sidebar' ? 's' : 'm'}
            onlyIcon={isMobile}
            showLeftIcon
            leftIcon={
              type === 'sidebar' ? (
                <i className={'icon icon-map-pin icon-tiny'} />
              ) : (
                <i className={'icon icon-map-pin icon-middle'} />
              )
            }
            onClick={flyToObject}
            style={{ gap: 4 }}
          />
        </>
      )}
      {((editorMode && !isDeleteObject) || (editorMode && type === 'sidebar')) && (
        <Button
          size={type === 'sidebar' ? 's' : 'm'}
          className={styles['object-info-buttons-group-button-display']}
          elementType={'button'}
          typeBtn={'secondary'}
          children={!isMobile && 'Задать положение'}
          onlyIcon={isMobile}
          onClick={() => dispatch(toggleCoordsEditor())}
          showLeftIcon
          leftIcon={
            type === 'sidebar' ? (
              <i className={'icon icon-map-pin icon-tiny'} />
            ) : (
              <i className={'icon icon-map-pin icon-middle'} />
            )
          }
        />
      )}
      {isDeleteObject && type !== 'sidebar' ? (
        <Button
          className={styles['object-info-buttons-group-button-display']}
          elementType={'button'}
          typeBtn={'danger'}
          children={!isMobile && 'Удалить объект'}
          onlyIcon={isMobile}
          onClick={() => {
            setDeleteModal(true);
          }}
          showLeftIcon
          leftIcon={<i className={'icon icon-Trashcan icon-middle'} />}
        />
      ) : (
        <>
          {/* <Button
            size={type === 'sidebar' ? 's' : 'l'}
            className={styles['object-info-buttons-group-button-favorite']}
            elementType={'button'}
            typeBtn={
              object && favoriteItems.includes(object.id)
                ? 'primary-score'
                : 'secondary'
            }
            children={
              type !== 'sidebar' && !isMobile
                ? ''
                : object && favoriteItems.includes(object.id)
                ? 'Из избранного'
                : 'В избранное'
            }
            isActive={object && favoriteItems.includes(object.id)}
            onlyIcon={type !== 'sidebar'}
            showLeftIcon
            disabled={toggleFavoriteButtonLoading}
            onClick={toggleFavorite}
            leftIcon={
              type === 'sidebar' ? (
                <i className={'icon icon-star icon-tiny'} />
              ) : (
                <i className={'icon icon-star icon-middle'} />
              )
            }
          /> */}
          <p className={styles['object-info-buttons-group-share-container']}>
            <Button
              // style={{ marginLeft: '10px' }}
              className={
                type === 'sidebar'
                  ? styles['object-info-buttons-group-share-container-button']
                  : ''
              }
              elementType={'button'}
              typeBtn={'secondary'}
              size={type === 'sidebar' ? 's' : 'm'}
              // children={'Поделиться'}
              showLeftIcon
              onlyIcon
              leftIcon={<i className={'icon icon-share icon-low'} />}
              onClick={shareButtonHandler}
            />
            <SpanTag
              className={`
            ${styles['object-info-buttons-group-share-container-tooltip']}
            ${
              isShareToolTipActive
                ? styles['object-info-buttons-group-share-container-tooltip-active']
                : ' '
            }
            `}
            >
              Ссылка скопирована
            </SpanTag>
          </p>
        </>
      )}
    </>
  );
};
