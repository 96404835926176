import type { FC } from 'react';
import type { ControlsWithTypesProps } from './controls.types';

import React from 'react';

import * as ControlTypes from './control-types';


export const Controls: FC<ControlsWithTypesProps> = React.memo(
  ({ type = 'withvolume', ...props }) => {
    return React.createElement(ControlTypes[type], props);
  },
);
