import type { FC } from 'react';
import type { MainImagePropsWithType } from './main-image.types';

import React from 'react';

import * as MainImageVariant from './views';


export const MainImage: FC<MainImagePropsWithType> = React.memo(
  ({ type, ...props }) => {
    return React.createElement(MainImageVariant[type], props);
  },
);
