import { useCallback } from 'react';
import styles from './image.styles.module.scss';
import type { ImageProps } from './image.types';

export const Image = ({
  alt = '',
  thumb,
  src,
  onLoad = () => {},
  loaded = false,
  error = null,
  className = '',
  ...props
}: ImageProps) => {
  const thumbSwitcher = useCallback(() => {
    if (typeof thumb === 'string') {
      return <img alt={alt} src={thumb} {...props} />;
    }
    return <span>{thumb}</span>;
  }, [src, loaded, error]);

  return (
    <>
      {loaded ? (
        <img
          onLoad={(e) => {
            onLoad(e);
          }}
          className={`${styles.image} ${className}`}
          alt={alt}
          src={src}
          {...props}
        />
      ) : (
        thumbSwitcher()
      )}
    </>
  );
};
