import type { FC } from 'react';
import type { PlayerHUDProps } from '../../player-hud.types';
import { Button } from '../../../../common'; 
import { PlayerTimeline } from '../../player-timeline/player-timeline';
import { Icon, Icons } from '../../icon/icon';
import { VolumeChanger } from '../../volume-changer/volume-changer';
import { VideoDuration } from '../../video-duration/video-duration';

import { format } from '../../video-duration/utils';

import styles from './player-hud.desktop.module.scss';
import classnames from 'classnames';


export const PlayerHUDDesktop: FC<PlayerHUDProps> = ({
  showVolumeChanger,
  mute,
  loaded,
  played,
  volume,
  playing,
  duration,
  fullscreen,
  onPlay,
  onMute,
  onPlayedChange,
  onSeekStart,
  onSeekEnd,
  onDownload: onDowload,
  onFullScreen,
  onVolumeChange,
  onNext,
  onPrevious,
  additionalLeftButtons,
  pausedHidden = false,
}) => {
  return (
    <div
      className={classnames(
        styles['player-hud-container'],
        fullscreen && styles['player-hud-container_fullscreen'],
        !playing && styles['player-hud-container_pause'],
      )}
      data-type="hud"
    >
      <div
        className={classnames(
          styles['player-hud-container-line'],
          pausedHidden
            ? styles['player-hud-container-controls_hidden']
            : styles['player-hud-container-controls_visable'],
        )}
      >
        <PlayerTimeline
          value={played}
          loaded={loaded}
          tooltipText={format(played * duration)}
          hoveredTooltipText={(value) => format(value * duration)}
          onChange={onPlayedChange}
          onSeekStart={onSeekStart}
          onSeekEnd={onSeekEnd}
        />
      </div>
      <div className={styles['player-hud-container-controls']}>
        <div className={styles['player-hud-container-controls-player']}>
          {!playing ? (
            <Button
              showLeftIcon
              leftIcon={
                <Icon icon={Icons.PLAY} className={styles['player-hud-icon']} />
              }
              typeBtn={'uncolored'}
              onClick={(e: any) => onPlay(e)}
              className={styles['player-hud-container-controls-button']}
            />
          ) : (
            <Button
              showLeftIcon
              leftIcon={
                <Icon
                  icon={Icons.PAUSE}
                  className={styles['player-hud-icon']}
                />
              }
              typeBtn={'uncolored'}
              onClick={(e: any) => onPlay(e)}
              className={styles['player-hud-container-controls-button']}
            />
          )}
          <Button
            showLeftIcon
            leftIcon={
              <Icon
                icon={Icons.BACKWARD}
                className={styles['player-hud-icon']}
              />
            }
            typeBtn={'uncolored'}
            onClick={onPrevious}
            className={classnames(
              styles['player-hud-container-controls-button'],
              pausedHidden
                ? styles['player-hud-container-controls_hidden']
                : styles['player-hud-container-controls_visable'],
            )}
          />
          <Button
            showLeftIcon
            leftIcon={
              <Icon
                icon={Icons.FORWARD}
                className={styles['player-hud-icon']}
              />
            }
            typeBtn={'uncolored'}
            onClick={onNext}
            className={classnames(
              styles['player-hud-container-controls-button'],
              pausedHidden
                ? styles['player-hud-container-controls_hidden']
                : styles['player-hud-container-controls_visable'],
            )}
          />
          {showVolumeChanger && (
            <VolumeChanger
              volume={volume}
              mute={mute}
              onChange={(val) => onVolumeChange(val)}
              onMute={onMute}
            />
          )}
          <VideoDuration
            seconds={duration}
            currentTime={played * duration}
            className={classnames(
              styles['player-hud-time'],
              pausedHidden
                ? styles['player-hud-container-controls_hidden']
                : styles['player-hud-container-controls_visable'],
            )}
          />
        </div>
        <div
          className={classnames(
            styles['player-hud-container-controls-left'],
            pausedHidden
              ? styles['player-hud-container-controls_hidden']
              : styles['player-hud-container-controls_visable'],
          )}
        >
          {additionalLeftButtons}
     
          <Button
            showLeftIcon
            leftIcon={
              <Icon
                icon={fullscreen ? Icons.CLOSE : Icons.OPEN}
                className={styles['player-hud-icon']}
              />
            }
            typeBtn={'uncolored'}
            onClick={onFullScreen}
            className={styles['player-hud-container-controls-button']}
          />
        </div>
      </div>
    </div>
  );
};
