import type { FC } from 'react';
import type { ControlsProps } from '../../player-hud.types';

import { Button } from '../../../../common';
import { Icon, Icons } from '../../icon/icon';

import styles from '../controls.module.scss';


export const ControlsWithoutVolumeChanger: FC<ControlsProps> = ({
  playing,
  onPlay,
  onPrevious,
  onNext,
}) => {
  return (
    <div className={styles.controls}>
      {!playing ? (
        <Button
          className={styles['controls-play']}
          size={'m'}
          showLeftIcon
          onlyIcon
          leftIcon={
            <Icon icon={Icons.PLAY} className={styles['player-hud-icon']} />
          }
          typeBtn={'uncolored'}
          onClick={onPlay}
        />
      ) : (
        <Button
          className={styles['controls-play']}
          size={'m'}
          onlyIcon
          showLeftIcon
          leftIcon={
            <Icon icon={Icons.PAUSE} className={styles['player-hud-icon']} />
          }
          typeBtn={'uncolored'}
          onClick={onPlay}
        />
      )}
      <Button
        className={styles['controls-backward']}
        size={'m'}
        onlyIcon
        showLeftIcon
        leftIcon={
          <Icon icon={Icons.BACKWARD} className={styles['player-hud-icon']} />
        }
        typeBtn={'uncolored'}
        onClick={onPrevious}
      />
      <Button
        className={styles['controls-forward']}
        size={'m'}
        onlyIcon
        showLeftIcon          
        leftIcon={
          <Icon icon={Icons.FORWARD} className={styles['player-hud-icon']} />
        }
        typeBtn={'uncolored'}
        onClick={onNext}
      />
    </div>
  );
};
