import type { FC } from 'react';
import type {
  IVideoGalleryContext,
  VideoGalleryContextProviderProps,
} from './video-gallery-context.types';
import type { IItem } from '../video-gallery.types';

import { createContext, useState } from 'react';

import { defaultValue } from './constants';


export const VideoGalleryContext =
  createContext<IVideoGalleryContext>(defaultValue);

export const VideoGalleryContextProvider: FC<
VideoGalleryContextProviderProps
> = ({ children, defaultIsFullscreen = false }) => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(defaultIsFullscreen);
  const [currentItem, setCurrentItem] = useState<IItem>();

  return (
    <VideoGalleryContext.Provider
      value={{ isFullscreen, setIsFullscreen, currentItem, setCurrentItem }}
    >
      {children}
    </VideoGalleryContext.Provider>
  );
};
