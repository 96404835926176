import type { FC } from 'react';
import type { IVolumeChangerProps } from './volume-changer.types';

import React, { useCallback } from 'react';
import { Icon, Icons } from '../icon/icon';
import { PlayerTimeline } from '../player-timeline/player-timeline';

import styles from './volume-changer.module.scss';
import classmanes from 'classnames';


export const VolumeChanger: FC<IVolumeChangerProps> = React.memo(
  ({ editable = true, mute = true, volume = 0, onChange, onMute }) => {
    const onVolumeChange = useCallback(
      (value: number) => {
        if (mute) {
          onMute(!mute);
        }

        if (value === 0) {
          onMute(true);
        }

        onChange(value);
      },
      [mute],
    );

    return (
      <div
        className={classmanes(
          styles['volume-container'],
          editable && styles['volume-container_editable'],
        )}
      >
        <Icon
          className={classmanes(styles['volume-container-icon'])}
          icon={
            mute
              ? Icons.VOLUME_MUTE
              : volume > 0.49
                ? Icons.VOLUME2
                : Icons.VOLUME1
          }
          onClick={() => onMute(!mute)}
        />
        {editable && (
          <PlayerTimeline
            value={mute ? 0 : volume}
            onChange={onVolumeChange}
            tooltipText={mute ? '0' : `${(volume * 100).toFixed(0)}`}
          />
        )}
      </div>
    );
  },
);
