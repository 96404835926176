import { FC, ChangeEvent, useCallback, useEffect, useMemo, useState, memo } from 'react';
import { useEditorModeStateSelector } from '../../../state';
import { Button, ToolTip } from '../../common';
import styles from './image-gallery-map.module.scss';
import { ServerErrorModal } from '../../server-error-modal/server-error-modal';
import { ImageGallery } from '../image-gallery';
import { useObjectMediaState } from '../../../history-state/selectors';
import type { ImageGalleryMapProps } from './image-gallery-map.type';

export const ImageGalleryMap: FC<ImageGalleryMapProps> = ({
  items,
  closeGalleryHandler,
  deleteImageHamdler,
  addObjectImageHandler,
  slideImageHandler,
}) => {
  const isEditMode = useEditorModeStateSelector();
  const [searchObjectMedia] = useObjectMediaState();
  const [addImageQueryError, setAddImageQueryError] = useState<unknown | null>(null);

  const [openModalError, setOpenModalError] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e?.target;
    if (field.value) {
      const img = field && field?.files !== null && field?.files[0];

      try {
        img && addObjectImageHandler(img);
      } catch (error: unknown) {
        setAddImageQueryError(error);
      }
    }
  };

  const galleryMedia: any = useMemo(() => {
    if (items) {
      return items.map((item) => {
        return {
          originalAlt: `фото`,
          original: item.path,
          compressed: item.path.replace('/media', '/media/compressed'),
          thumbnail: item.path_thumbnail ? item.path_thumbnail : item.path,
          id: item.id,
        };
      });
    }
  }, [searchObjectMedia, items]);

  const handleSlide = useCallback(
    (imageId: number) => {
      slideImageHandler(imageId);
    },
    [searchObjectMedia],
  );

  const tollText = (
    <div className={styles['gallery-popup-message']}>
      <div className={styles['gallery-popup-message-body']}>
        Допустимый формат фотографии <b>png, jpg, jpeg</b>. Размер файла не должен
        превышать <b>15МБ</b>.
      </div>
    </div>
  );

  const initIndex = useMemo(() => {
    if (searchObjectMedia) {
      return galleryMedia.findIndex(
        (item: { id: number }) => item.id === Number(searchObjectMedia?.itemId),
      );
    }
  }, [searchObjectMedia?.itemId, items]);

  useEffect(() => {
    setOpenModalError(openModalError);
  }, [openModalError]);

  return !!searchObjectMedia ? (
    <div className={styles['gallery-panel']}>
      {openModalError && (
        <ServerErrorModal
          isError={!!addImageQueryError}
          onClose={() => setOpenModalError(false)}
        />
      )}
      <div className={styles['gallery-panel_btn-box']}>
        {isEditMode && (
          <>
            <form>
              <label
                htmlFor="file-upload"
                className={styles['gallery-panel_btn-box_add-btn']}
              >
                <ToolTip isVisible content={tollText}>
                  Добавить фото
                  <input
                    onChange={handleChange}
                    id="file-upload"
                    type="file"
                    name="img"
                    value=""
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </ToolTip>
              </label>
            </form>
            <button
              onClick={deleteImageHamdler}
              className={styles['gallery-panel_btn-box_delete-btn']}
            >
              Удалить фото
            </button>
          </>
        )}
        <Button
          style={{ paddingTop: 3 }}
          elementType={'button'}
          onlyIcon={true}
          typeBtn={'uncolored'}
          className={'ms-auto me-0'}
          onClick={closeGalleryHandler}
          showLeftIcon
          leftIcon={<i className={'icon icon-close-3 icon-middle'} />}
        />
      </div>
      {items.length && (
        <ImageGallery
          items={galleryMedia}
          initIndex={initIndex}
          onSlideChange={handleSlide}
          thumbnailClassName={styles['gallery-panel-thumbnails-container']}
          navigateButtonPrevClassName={styles['gallery-panel-navigation-button-prev']}
          navigateButtonNextClassName={styles['gallery-panel-navigation-button-next']}
        />
      )}
    </div>
  ) : null;
};
