import type { FC } from 'react';
import type { GalleryNavButtonProps } from './gallery.types';
import screenfull from 'screenfull';

import { useSwiper } from 'swiper/react';

import styles from './gallery.module.scss';
import classnames from 'classnames';


export const NavigationButton: FC<GalleryNavButtonProps> = ({
  type,
  visable,
  className = '',
  ...props
}) => {
  const swiper = useSwiper();

  return (
    <div
      className={classnames(
        styles['gallery-swiper-button'],
        styles[`gallery-swiper-button-${type}`],
        screenfull.isFullscreen && styles['gallery-swiper-button_fullscreen'],
        !visable && styles['gallery-swiper-button_hidden'],
        className,
      )}
      onClick={() =>
        type === 'next' ? swiper.slideNext() : swiper.slidePrev()
      }
      {...props}
    >
      <i
        className={classnames(
          `icon icon-arrow-2-${type === 'next' ? 'right' : 'left'} icon-middle`,
          styles['gallery-swiper-button-icon'],
        )}
      />
    </div>
  );
};
