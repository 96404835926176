import { Button } from '../../../common';
import { Icon, Icons } from '../icon/icon';
import styles from './paused-modal.module.scss';


export const PausedModal = () => {
  return (
    <div className={styles.container}>
      <Button
        className={styles['container-button']}
        showLeftIcon
        onlyIcon
        leftIcon={
          <Icon icon={Icons.PLAY} className={styles['player-hud-icon']} />
        }
        typeBtn={'uncolored'}
      />
    </div>
  );
};
